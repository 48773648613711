<template>
  <div>
   <v-app-bar app flat color="primary" dark prominent>

        <v-btn icon :to="'/home'" v-if="this.$store.getters.previousRoute == null">
            <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn icon @click="goback" v-else>
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title class="text-h4 font-weight-light" style="letter-spacing:2px !important">FAQ</v-toolbar-title>

        <v-spacer></v-spacer>

        <template v-slot:img>
        <v-icon size="180" class="pl-8 pt-4 float-left" color="#fff" style="opacity:.15">mdi-frequently-asked-questions</v-icon>
      </template>

        

    </v-app-bar>

    <v-row no-gutters>
        <v-col class="py-4 pl-6">
            <span class="text-h6 grey--text text-uppercase text--lighten-2" style="letter-spacing:2px !important">General</span>
        </v-col>
    </v-row>

    <v-row no-gutters>
        <v-col>
            <v-expansion-panels flat accordion>
                <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-medium">
                        Why do I need to add my pet details?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="body-2-custom grey--text">
                        <p>We require your pet's details So that we can find the best matches for your pet near to your location.</p>
                        <v-divider class="mt-6"></v-divider>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-medium">
                        How can I upload photos of my pet?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="body-2-custom grey--text">
                        <p>You can upload as many photos of your pet.</p>
                        <ol style="line-height:1.8em">
                            <li>Go to <a href="javascript:void(0);" @click="goNav('home')" class="priamry--text"><v-icon small>mdi-home-outline</v-icon> Home</a></li>
                            <li>Click <v-icon small>mdi-dots-vertical</v-icon> icon on your pet and choose "Edit."</li>
                            <li>Upload a cover photo and add more photos to the bottom of the page</li>
                        </ol>
                        <v-divider class="mt-6"></v-divider>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-medium">
                        How can I delete my pet?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="body-2-custom grey--text">
                        <p>You can keep your pet's profile hidden so that it won't appear on searches.</p>
                        <ol style="line-height:1.8em">
                            <li>Go to <a href="javascript:void(0);" @click="goNav('home')" class="priamry--text"><v-icon small>mdi-home-outline</v-icon> Home</a></li>
                            <li>Click <v-icon small>mdi-dots-vertical</v-icon> icon on your pet and choose "Hide"</li>
                            <li>Or Click <v-icon small>mdi-dots-vertical</v-icon> icon on your pet and choose "Delete"</li>
                        </ol>
                        <v-divider class="mt-6"></v-divider>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-medium" style="line-height:1.6em">
                        I'm getting too many emails. How to disable it?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="body-2-custom grey--text">
                        <p>You can set the frequency of our Match Notifications from "Settings > Notifications"</p>
                        <ol style="line-height:1.8em">
                            <li>Go to <a href="javascript:void(0);" @click="goNav('notifications')" class="priamry--text"> Settings > Notifications</a> from menu.</li>
                            <li>Change the frequency or choose "Off" to disable it.</li>
                        </ol>
                        <v-divider class="mt-6"></v-divider>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-medium" style="line-height:1.6em">
                        What is a "Direct Connect"
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="body-2-custom grey--text">
                        <p>With 'Direct Connect,' you can access the contact details to reach out to other pet owners directly via email or phone number.</p>
                        
                        <v-divider class="mt-6"></v-divider>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                
            </v-expansion-panels>
        </v-col>
    </v-row>

    <v-row no-gutters>
        <v-col class="py-4 pl-6">
            <span class="text-h6 grey--text text-uppercase text--lighten-2" style="letter-spacing:2px !important">Billing &amp; Subscription</span>
        </v-col>
    </v-row>

    <v-row no-gutters>
        <v-col>
            <v-expansion-panels flat accordion>

                <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-medium">
                        Direct Connects are pricey.
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="body-2-custom grey--text">
                        <p>We value the privacy of our users, and we don't want our users to be disturbed by scammers/spammers. By keeping it with a price, we would like to limit the access to only genuine inquiries.</p>
                        <v-divider class="mt-6"></v-divider>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <!-- <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-medium" style="line-height:1.6em">
                        How much does a premium membership cost?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="body-2-custom grey--text">
                        <p>We offer monthly, quarterly, semi-annual and annual subscriptions for Premium Membership. All our memberships come with a limited quantity of Direct connects.</p>
                        <ul style="line-height:1.6em">
                            <li>Monthly: $19.99 per month (+ 2 Direct Connects)</li>
                            <li>Quarterly: $44.99 per 3 months  (+ 3 Direct Connects)</li>
                            <li>Semi-Annual: $71.99 per 6 months  (+ 4 Direct Connects)</li>
                            <li>Annual: $119.99 per 12 months  (+ 5 Direct Connects)</li>
                        </ul>
                        <v-divider class="mt-6"></v-divider>
                    </v-expansion-panel-content>
                </v-expansion-panel> -->

                <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-medium" style="line-height:1.6em">
                        How does Petmeetly charge my account?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="body-2-custom grey--text">
                        <p>Your subscription will automatically renew at the standard pricing and cadence unless you cancel before the end of the billing period.</p>
                        <p>If you would like to know your next billing date, current plan or the expiry date you can visit the <a href="javascript:void(0);" @click="goNav('membership')" class="priamry--text"> Settings > Membership</a> screen after you sign in.</p>
                        <v-divider class="mt-6"></v-divider>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-medium">
                        Why was my payment declined?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="body-2-custom grey--text">
                        <p>If we showed you an error while attempting to process your credit card, please double-check your payment information and try again.</p>
                        <p>Only your bank or credit card issuer can confirm the specific reason. Here are a few of the most common ones:</p>
                        <ul style="line-height:1.6em">
                            <li>Incorrect credit card number or expiration date</li>
                            <li>Insufficient funds</li>
                            <li>Some banks will reject charges based on location; You should enable international online payments</li>
                            <li>Some banks will reject orders based on their own fraud rules</li>
                        </ul>
                        <v-divider class="mt-6"></v-divider>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-medium" style="line-height:1.6em">
                        How to cancel my Premium subscription?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="body-2-custom grey--text">
                        <p>You can cancel your subscription from the <a href="javascript:void(0);" @click="goNav('membership')" class="priamry--text"> Settings > Membership</a> screen.</p>
                        <p>At the end of your current billing cycle for your subscription, your account will be downgraded to the free version and all future payments will be canceled.</p>
                        <v-divider class="mt-6"></v-divider>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-medium">
                        Can I get a refund?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="body-2-custom grey--text">
                        <p>Except as described in the Service Agreement, Petmeetly Premium subscriptions and Direct Connect purchases are non-refundable.</p>
                        <p>Regarding our membership plans, the following refund policies apply:</p>
                        <p><strong>Monthly Memberships:</strong> Please note that monthly memberships are not eligible for refunds. 
                            We encourage our monthly members to fully explore the benefits of their membership during the active month.</p>
                        <p><strong>Quarterly, Semi-Annually, and Annual Memberships:</strong> Members who have opted for quarterly, semi-annual, 
                            or annual plans may be eligible for partial refunds. 
                            These refunds will be calculated based on the usage of the membership during the active period. 
                            We understand that circumstances change, and we aim to be as accommodating as possible within these parameters.</p>
                        <p>If, for any reason, you are not completely satisfied with any good or service we provide, 
                            please don't hesitate to contact us. We are here to assist and will discuss any issues you may have with 
                            our products or services.</p><v-divider class="mt-6"></v-divider>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-medium" style="line-height:1.6em">
                        Why am I getting billed after I canceled?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="body-2-custom grey--text">
                        <p>If you have canceled your subscription but still receive bills, you might have more than one account with Petmeetly. Please check your mailboxes and let us know so that we can help you cancel those additional subscriptions and refund the duplicate payments.</p>
                        <v-divider class="mt-6"></v-divider>
                    </v-expansion-panel-content>
                </v-expansion-panel>

            </v-expansion-panels>
        </v-col>
    </v-row>

  <div class="py-16"></div>

    <v-snackbar v-model="snackbar" bottom>
        {{ snackbar_text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>
  </div>

 

</template>


<script>

  export default {
    name: 'Faq',
    mounted() {

        this.name = this.$store.getters.userDetails.name;
        this.email = this.$store.getters.userDetails.email;

    },
    data: () => ({
        valid: true,
        snackbar: false,
        snackbar_text: '',
    }),

     methods: {

        goback()
        {
            this.$router.go(-1);
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage("Back Button Clicked");
            }
        },

        goNav(path)
        {
            this.$router.push(path);
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage("Link Clicked - "+path);
            }
        },
    },
    
  }
</script>
